<template>
  <div class="count-finance-getcash">
    <el-alert
      :title="'当前有  ' + count + '  笔提现申请，等待平台审核打款'"
      type="error"
      v-if="count > 0"
    >
    </el-alert>
    <div class="search mb20 mt20">
      <!-- <el-radio-group @change='chooseTime(shopDays,shopSearchInfo)' v-model='shopDays'>
        <el-radio-button label='1'>昨天</el-radio-button>
        <el-radio-button label='7'>最近7天</el-radio-button>
        <el-radio-button label='30'>最近30天</el-radio-button>
        <el-radio-button label='0'>自定义</el-radio-button>
      </el-radio-group> -->
      <!-- <el-button type="primary" @click="showPicker=!showPicker">自定义</el-button> -->
      <!-- <el-date-picker
        v-model="shopSearchInfo.startTime"
        type="date"
        value-format="yyyy-MM-dd"
        style="width: 180px; margin-right: 20px"
        placeholder="开始日期"
      >
      </el-date-picker>
      <el-date-picker
        v-model="shopSearchInfo.endTime"
        type="date"
        value-format="yyyy-MM-dd"
        style="width: 180px; margin-right: 20px"
        placeholder="结束日期"
      >
      </el-date-picker> -->
         <el-date-picker
          v-model="addTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
           style=" margin-right: 20px;vertical-align: -2px;"
          end-placeholder="结束日期">
        </el-date-picker>
      <!-- <el-select
        v-model="shopSearchInfo.spreadId"
        placeholder="归属分公司"
        clearable
        filterable
        style="width: 180px; margin-right: 20px"
      >
        <el-option
          v-for="item in branchOfficeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
       <el-select v-model="shopSearchInfo.spreadId" placeholder="请选择归属分公司" clearable filterable remote class="ml20"
            :loading="loading" :remote-method="searchBranchOffice" @change="spreadIdChange" style=" margin-right: 20px" >
            <!--  @focus="searchBranchOffice('')" -->
            <el-option v-for="item in branchOfficeList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
            <el-select v-model="shopSearchInfo.shopId" placeholder="请选择商户" clearable filterable remote class="ml20"
          :loading="loading" :remote-method="searchShop" style=" margin-right: 20px">
          <!--  @focus="searchShop('')" -->
            <el-option v-for="item in  shopList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
      <!-- <el-select
        style="margin-right: 20px"
        v-model="shopSearchInfo.shopId"
        filterable
        clearable
        placeholder="商户名称"
      >
        <el-option
          v-for="item in activeShopList"
          :key="item.shopId"
          :label="item.shopName"
          :value="item.shopId"
        >
        </el-option>
      </el-select> -->
      <el-select
        v-model="shopSearchInfo.status"
        filterable
        clearable
        remote
        reserve-keyword
        placeholder="提现状态"
        @focus="remote('')"
        :remote-method="remote"
        class="select"
        :inline="true"
      >
        <el-option label="等待打款" value="1"></el-option>
        <el-option label="出款失败" value="3"></el-option>
        <el-option label="打款成功" value="4"></el-option>
        <el-option label="申请驳回" value="5"></el-option>
      </el-select>
      <el-button type="primary" @click="search('search')">搜索</el-button>
      <el-button type="info" @click="clear">清空</el-button>
    </div>
    <div class="paginationData">
      <div></div>
      <PrevReclick rights="g_withdraw_export">
          <el-button size="medium" type="primary" @click="exportData" class="export">导出数据</el-button>
      </PrevReclick>

    </div>
    <el-table :data="shopTableData" border stripe style="width: 100%">
      <el-table-column
        prop="shopName"
        label="店铺名称"
        show-overflow-tooltip
        min-width="120"
      ></el-table-column>
      <el-table-column

        prop="spreadName"
        label="所属分公司"
        min-width="80"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="withdrawMoney"
        label="提现金额"
        align="center"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="trueName"
        label="收款户名"
        width="90"
      ></el-table-column>
      <el-table-column
        prop="accounts"
        label="收款账号"
        min-width="130"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="shopTel"
        label="联系电话"
        width="110"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="申请时间"
        :formatter="dateFormat"
        width="160"
      ></el-table-column>
      <el-table-column prop="status" label="提现状态" width="80">
        <template slot-scope="scope">
          <p :class="colorArr[scope.row.status - 1]">
            {{ formatType(scope.row) }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        min-width="110"
        prop="withdrawDesc"
        label="说明"

      >
       <template slot-scope="scope">
          <p >
            {{scope.row.status==4?'':scope.row.withdrawDesc }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="updateTime"
        label="操作时间"
        :formatter="dateFormat"
        width="160"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
        <PrevReclick rights="p_withdraw_verifyShopState">
          <el-button
            size="mini"
            type="primary"
            @click="
              makeSure(
                scope.row.mchWithdrawId,
                scope.row.trueName,
                scope.row.withdrawMoney,
                scope.row.accounts,
                scope.row.accounts_qr,
                scope.row.withdraw_type
              )
            "
            v-if="scope.row.status === 1"
            >确认打款</el-button
          >
          </PrevReclick>

          <PrevReclick rights="p_withdraw_verifyShopState">
            <el-button
              size="mini"
              type="primary"
              @click="reject(scope.row.mchWithdrawId)"
              v-if="scope.row.status === 1"
              >驳回申请</el-button
            >
          </PrevReclick>

        <span v-if="scope.row.status !== 1">——</span>
        </template>
      </el-table-column>
    </el-table>
      <el-pagination
        background
        :page-count="shopTotalPages"
        :current-page.sync="shopCurrentPage"
        @current-change="shopCurrentChange"
        :pager-count="pagerCount"
        class="mb10"
        style="display: inline-block"
      >
      </el-pagination>
    <el-dialog
      title="确认打款"
      :visible.sync="dialogVisible"
      width="30%"
      center
      class="minw"
    >
      <div v-if="cash[0].withdraw_type === 6">
        <p class="topmoney">
          平台打款方式为【自动打款】，确认打款后系统将调用平台绑定打款的微信商户，自动将提现金额下发到商户绑定的微信零钱中。请确认是否打款？
        </p>
      </div>
      <div v-if="cash[0].withdraw_type !== 6">
        <p class="topmoney">
          确认打款后出款平台将立即向此账户出款，请仔细核实！
        </p>
        <el-table :data="cash" border stripe style="width: 100%">
          <el-table-column prop="trueName" label="户名" width="180">
          </el-table-column>
          <el-table-column prop="accounts" label="账号" width="180">
          </el-table-column>
          <el-table-column prop="withdrawMoney" label="金额"> </el-table-column>
        </el-table>
        <!-- <img :src="cash[0].accounts_qr|uploadImg" alt="收款二维码"
        class="qrCode"> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisible = false"
          v-if="cash[0].withdraw_type === 6"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="make"
          v-if="cash[0].withdraw_type === 6"
          >确 认</el-button
        >
        <el-button
          type="primary"
          @click="make"
          v-if="cash[0].withdraw_type !== 6"
          >确认打款</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="驳回申请"
      :visible.sync="rejectVisible"
      width="30%"
      center
      class="minw"
        @close="closeReject"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="驳回说明">
          <el-input
            type="textarea"
            v-model="form.withdraw_desc"
            placeholder="请备注驳回理由"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="rejectGet">确认驳回</el-button>
      </span>
    </el-dialog>
    <!-- 引入请求导出的组件 -->
    <!-- <export-tips :comNmae='$options.name' :exportParams='exportParams' ></export-tips> -->
  </div>
</template>

<script>
import { DEFAULT_DATA_PAGES } from '@/config';
// import ExportTips from '@/components/common/ExportTips';
// import store from '@/store';
import {
  $sg_getShops,
  $sg_countApplyingWithdraw,
  $sg_getShopWithdraws,
  $sp_spreadRejectShopApply,
  $sp_reviewedShopPage,

  // $sp_spreadAgreeShopApply,
} from '@/api';
import {
  $sp_agentList, $sp_spreadList, $sp_shopInfoList,
} from '@/api/order';
import { exportFileSync } from '@base/utils';

export default {
  name: 'CountFinanceGetcash',
  // components: { ExportTips },
  //   inject: ['validateAuthCode'],
  data() {
    return {
      addTime: null,
      branchOfficeList: [],
      uploadImg: '',
      openMoreArea: true,
      exportParams: {},
      shopSearchInfo: {
        shopId: '',
        status: '',
        agent_sid: '',
        spreadId: '',
      },
      shopTotalPages: DEFAULT_DATA_PAGES,
      totalPages: DEFAULT_DATA_PAGES,
      shopCurrentPage: 1,
      currentPage: 1,
      shopTableData: [],
      loading: false,
      agentList: [],
      shopList: [],
      tableData: [],
      activeShopList: [],
      shopDays: 1,
      dialogVisible: false,
      rejectVisible: false,
      form: {
        shop_withdraw_id: '',
        withdraw_desc: '',
      },
      spanHidden: false,
      spanShow: false,
      pagerCount: 15,
      formInline: {
        money: '',
        region: '',
      },
      cash: [
        {
          shop_withdraw_id: '',
          trueName: '',
          withdrawMoney: '',
          accounts: '',
          accounts_qr: '',
          withdraw_type: '',
        },
      ],
      qrPic: '',
      count: '',
      showPicker: false,
      colorArr: ['red', '', '', 'green'],
    };
  },
  methods: {
    // 驳回申请
    closeReject() {
      this.form.withdraw_desc = '';
    },
    // 搜索归属分公司
    clear() {
      this.shopSearchInfo = {
        shopId: '',
        startTime: '',
        endTime: '',
        status: '',
        agent_sid: '',
        spreadId: '',
      };
      this.addTime = '';
      this.loadShopData();
    },
    spreadIdChange() {
      this.shopSearchInfo.agentSid = '';
      this.shopSearchInfo.shopId = '';
      this.getAgentList();
      this.getShopList();
    },
    // 请求归属分公司数据
    reviewedShopPage(platName = '') {
      const params = {
        platName,
      };
      return $sp_reviewedShopPage(params).then((res) => {
        const tempArr = [];
        res.records.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 搜索商家数据
    search(param) {
      const start = new Date(`${this.shopSearchInfo.startTime}`).getTime();
      const end = new Date(`${this.shopSearchInfo.endTime}`).getTime();
      console.log(start, end, '3333');
      if (start > end) {
        this.$message.error('开始时间不能大于结束时间');
        return;
      }
      this.loadShopData(param);
    },
    // 选择页码
    shopCurrentChange() {
      this.loadShopData();
    },
    loadShopData(param) {
      this.shopSearchInfo.startTime = this.addTime && this.addTime.length > 0 ? this.$formatDateByDate(this.addTime[0], 'yyyy-MM-dd') : '';
      this.shopSearchInfo.endTime = this.addTime && this.addTime.length > 0 ? this.$formatDateByDate(this.addTime[1], 'yyyy-MM-dd') : '';
      const params = {
        pageCurrent: param ? 1 : this.shopCurrentPage,
        pageSize: 15,
        ...this.shopSearchInfo,
      };
      $sg_getShopWithdraws(params).then((res) => {
        this.shopTableData = res.list;
        this.shopTotalPages = res.pages;
        // res.data.forEach((item) => {
        //   item.shop_name = item.shop.shop_name;
        //   item.agent_name = item.shop.agent.agent_name;
        // });
        // this.shopTableData = res.data;
        // console.log(this.shopTableData);
        // this.shopTotalPages = res.count;
      });
    },

    remote(query) {
      console.log(query);
    },
    // 输入店铺名称的列表
    loadShopList() {
      $sg_getShops().then((res) => {
        this.activeShopList = res;
      });
    },
    // 确认打款
    async makeSure(
      shop_withdraw_id,
      trueName,
      withdrawMoney,
      accounts,
      accounts_qr,
      withdraw_type,
    ) {
      this.form.shop_withdraw_id = shop_withdraw_id;
      this.dialogVisible = true;
      this.cash[0].shop_withdraw_id = shop_withdraw_id;
      this.cash[0].trueName = trueName;
      this.cash[0].withdrawMoney = withdrawMoney;
      this.cash[0].accounts_qr = accounts_qr;
      this.cash[0].accounts = accounts;
      this.cash[0].withdraw_type = withdraw_type;
    },
    // 驳回申请
    reject(id) {
      this.rejectVisible = true;
      this.form.shop_withdraw_id = id;
    },
    // 确认转账
    make() {
      const params = {
        mchWithdrawId: this.form.shop_withdraw_id,
        status: '1',
      };
      $sp_spreadRejectShopApply(params).then(() => {
        this.dialogVisible = false;
        this.form = {
          shop_withdraw_id: '',
          withdraw_desc: '',
        };
        this.loadShopData();
        this.countApplyingWithdraw();
      });
    },
    // 驳回申请提交 mch_withdraw_id
    rejectGet() {
      if (this.form.withdraw_desc.length > 20) {
        this.$message.error('请输入驳回申请20个字符以内');
        return;
      }
      const params = {
        mchWithdrawId: this.form.shop_withdraw_id,
        status: '2',
        withdrawDesc: this.form.withdraw_desc,
      };
      $sp_spreadRejectShopApply(params).then(() => {
        this.rejectVisible = false;
        this.form = {
          shop_withdraw_id: '',
          withdraw_desc: '',
        };
        this.loadShopData();
        this.countApplyingWithdraw();
      });
    },
    // 平台获取当前多少比提现申请
    countApplyingWithdraw() {
      $sg_countApplyingWithdraw().then((res) => {
        this.count = res;
        // 这个地方报错了，先判断一下
        const result = this.$store.state.menu.menu.find(
          (value) => value.id === 42,
        );
        if (!result) return;
        const child = result.children.find((value) => value.id === 71);
        if (!child) return;
        const children = child.children.find((value) => value.id === 77);
        if (!children) return;
        if (res > 0) {
          child['is-dot'] = true;
          children['is-dot'] = true;
        } else {
          child['is-dot'] = false;
          children['is-dot'] = false;
        }
      });
    },
    formatType({ status }) {
      const Type = ['等待打款', '', '出款失败', '打款成功', '申请驳回'];
      return Type[status - 1];
    },
    dateFormat(row, column) {
      const adate = row[column.property];
      if (adate === 0) {
        return '——';
      }
      return this.$formatDate(adate, 'yyyy-MM-dd HH:mm');
    },
    exportData() {
      const params = { export: 1 };
      exportFileSync(
        '/j/platform/withdraw/export',
        params,
        'get',
        '商户提现.xls',
      );
    },
    // 抄袭
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.getSpreadList(query);
      } else {
        await this.getSpreadList('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    getSpreadList(platName = '') {
      const params = {
        platName,
      };
      this.branchOfficeList = [];
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 选择归属分公司
    // 搜索代理商
    async searchAgent(query) {
      this.loading = true;
      if (query) {
        await this.getAgentList(query);
      } else {
        await this.getAgentList('');
      }
      this.loading = false;
    },
    // 请求代理商列表
    getAgentList(agentName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentName,
      };
      this.agentList = [];
      return $sp_agentList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.agentSid,
            label: item.agentName,
          };
          tempArr.push(json);
        });
        this.agentList = tempArr;
      });
    },
    // 选择代理商
    agentSidChange() {
      this.form.shopId = '';
      this.getShopList();
    },
    // 搜索商户
    async searchShop(query) {
      this.loading = true;
      if (query) {
        await this.getShopList(query);
      } else {
        await this.getShopList('');
      }
      this.loading = false;
    },
    // 请求商户列表
    getShopList(shopName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentSid: this.form.agentSid,
        shopName,
      };
      this.shopList = [];
      return $sp_shopInfoList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.shopId,
            label: item.shopName,
          };
          tempArr.push(json);
        });
        this.shopList = tempArr;
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
  created() {
    // const date = new Date();
    // date.setTime(date.getTime() - 3600 * 1000 * 24); //eslint-disable-line
    // this.shopSearchInfo.start_time = this.$formatDateByDate(date, 'yyyy-MM-dd');
    // this.shopSearchInfo.end_time = this.$formatDateByDate(date, 'yyyy-MM-dd');
    this.getSpreadList();
    this.getAgentList();
    this.getShopList();
    this.loadShopData();
    this.loadShopList();
  },
};
</script>
<style lang="scss">
@import "@/style/var.scss";
.count-finance-getcash {
  .el-form-item__content {
    margin-left: 120px;
  }
  .select {
    width: 104px;
    margin-right: 12px;
  }
  .selectvalue {
    width: 70%;
    margin-right: 10px;
  }
  .bz {
    margin-top: 10px;
  }
  .topmoney {
    margin-bottom: 20px;
  }
  .el-form-item {
    margin-bottom: 5px;
  }
  .el-table th div {
    line-height: 30px;
    display: block;
  }
  .qrCode {
    width: 250px;
    display: block;
    margin: 30px auto 0;
  }
  .minw {
    .el-dialog--center {
      min-width: 570px !important;
    }
  }
}
.paginationData {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .export {
    margin-right: 10px;
  }
}
</style>
